<template>
	<div class="wrapper">
		<div class="element" v-for="d in data" :key="d.label">
			<div class="label">{{ d.label }}</div>
      <countTo class="value" :startVal="0" :endVal="d.value" :duration="CountAnimationDuration"></countTo>
		</div>
	</div>
</template>
<script>
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';

export default {
	props: ['data'],
  components: {countTo},
  data() {
    return {
      CountAnimationDuration
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.wrapper {
	width: 100%;
	display: flex;
  flex-wrap: wrap;
}
.element {
	display: flex;
  height: 38px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
	border: 1px solid #1E4078;
  background-color: #0B2550;
  .label {
    width: 60%;
    text-align: center;
    color: white;
  }
  .value {
    border-left: 1px solid #1E4078;
    width: 40%;
    text-align: center;
    color: $main-text;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
