<template>
  <div class="bordered">
    <Title>巡诊、驻派、随访</Title>
    <div class="cardContainer">
      <StatisticsCard :name="i.name" :value="i.value" :key="i.name" v-for="i in cardDataByLevel">
      </StatisticsCard>
    </div>
    <BaseEchart :option="option" style="width: 100%; height: 330px"></BaseEchart>
  </div>
</template>
<script>
import Title from '../components/Title';
import StatisticsCard from '../components/StatisticsCard';
import BaseEchart from '@/components/BaseEchart';
import MockChinaData from '@/utils/MockChinaData'
import { random } from '@/utils'
import {slider} from '../common'
import * as echarts from 'echarts';
import { mapState } from 'vuex';
import { randomByLevel } from '@/views/homePage/common';
export default {
  components: { BaseEchart, Title, StatisticsCard },
  data() {
    return {
      option: null,
      showNoData: false,
      cardData: [{ name: '巡诊总量', value: 1025 }, { name: '驻派总量', value: 1025 }, { name: '随访总数', value: 825 }, { name: '服务人员', value: 2042 }, { name: '医护人员', value: 159 }]
    };
  },
  mounted() {
    this.initChart()
  },
  computed:{
    ...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    cardDataByLevel(){
      return this.cardData.map(item=>{
        return {
          ...item,
          value:item.value/(this.mapLevel+1)
        }
      })
    }
  },
  methods: {
    initChart(){
      const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
      this.option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: 7,
          right: 16,
          bottom: 16,
          top: 48,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: dataX.map(d => d.name),
            axisLine: {
              show: true,
              lineStyle: {
                type: [5, 5],
                color: '#063374',
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              show: true,
              textStyle: {
                color: '#fff',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '(数量)',
            nameTextStyle: {
              color: '#fff',
              align: 'right',
            },
            axisLabel: {
              formatter: '{value}',
              color: '#fff',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#00c7ff',
                width: 1,
                type: 'solid',
              },
            },
            splitLine: {
              lineStyle: {
                color: '#063374',
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            data: dataX.map(d => d.value),
            barWidth: 16,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#4AAEFF',
                  },
                  {
                    offset: 1,
                    color: '#72E4F3',
                  },
                ]),
                opacity: 1,
              },
            },
          },
        ],
        dataZoom: slider(dataX.length)
      }
    }
  },
  watch: {
    mapDataArr: {
      handler() {
        this.initChart()
        
	}
    }
  },
}
</script>
<style lang="scss" scoped>

.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.cardContainer {
  display: flex;
  gap: 8px;
  padding: 8px;
}
</style>