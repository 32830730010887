<template>
  <div class="bordered">
    <Title>重点业务覆盖率</Title>
    <el-tabs class="tabs" @tab-click="initChart" v-model="activeName">
      <el-tab-pane label="巡诊覆盖率" name="first" class="as"></el-tab-pane>
      <el-tab-pane label="驻派覆盖率" name="second" class="as"></el-tab-pane>
      <el-tab-pane label="随访覆盖率" name="third"></el-tab-pane>
    </el-tabs>
    <BaseEchart :option="option" style="width: 100%; height: 402px"></BaseEchart>
  </div>
</template>
<script>
import Title from '../components/Title';
import BaseEchart from '@/components/BaseEchart';
import MockChinaData from '@/utils/MockChinaData'
import { random } from '@/utils'
import * as echarts from 'echarts'
import { randomByLevel } from '@/views/homePage/common';
import { mapState } from 'vuex';
export default {
  components: { Title, BaseEchart },
  data() {
    return {
      activeName: 'first',
      option: undefined,
      barData:[]
    }
  },
  mounted() {
    this.initChart()
  },
  computed:{
    ...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
  },
  watch: {
    mapDataArr: {
      handler() {
        this.initChart() 
	}
    }
  },
  methods: {
    initChart() {
        this.barData = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
      this.option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: 8,
          right: 18,
          bottom: 16,
          top: 0,
          containLabel: true,
        },
        yAxis: [
          {
            type: 'category',
            data: this.barData.map(d => d.name),
            axisLine: {
              show: true,
              lineStyle: {
                type: [5, 5],
                color: '#063374',
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
              },
            },
          },
          {
            type: 'category',
            axisLabel: {
              formatter: function (n) {
                return n + "%";
              },
              textStyle: {
                color: '#fff',
                fontSize: '12'
              }
            },
            data: this.barData.map(d => d.value),
          },
        ],
        xAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              align: 'right',
            },
            axisLabel: {
              formatter: '{value}',
              color: '#fff',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#00c7ff',
                width: 1,
                type: 'solid',
              },
            },
            splitLine: {
              lineStyle: {
                color: '#063374',
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            data: this.barData.map(d => d.value),
            barWidth: 16,
            barGap: '80%',/*多个并排柱子设置柱子之间的间距*/
            barCategoryGap: '50%',/*多个并排柱子设置柱子之间的间距*/
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#4AAEFF',
                  },
                  {
                    offset: 1,
                    color: '#72E4F3',
                  },
                ]),
                opacity: 1,
              },
            },
          },
        ],
        dataZoom: [{
          type: 'slider',
          realtime: true,
          yAxisIndex: [0, 1],
          orient: "vertical",
          start: 0,
          end: 90, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
          height: 8, //组件高度
          left: 2, //左边的距离
          right: 10, //右边的距离
          bottom: 0, //下边的距离
          show: length > 7, // 是否展示
          backgroundColor: '#051127',
          fillerColor: '#223A69', // 滚动条颜色
          borderColor: '#051127',
          handleStyle: {},
          borderRadius: 4, // 滚动条圆角
          handleSize: 0, //两边手柄尺寸
          showDetail: false, //拖拽时是否展示滚动条两侧的文字
          zoomLock: true, //是否只平移不缩放
          moveOnMouseMove: false, //鼠标移动能触发数据窗口平移

          //下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
          startValue: 0, // 从头开始。
          endValue: 6, // 最多六个
          minValueSpan: 6, // 放大到最少几个
          maxValueSpan: 6, //  缩小到最多几个
        },
        {
          yAxisIndex: [0],
          type: 'inside', // 支持内部鼠标滚动平移
          start: 0,
          end: 35,
          zoomOnMouseWheel: false, // 关闭滚轮缩放
          moveOnMouseWheel: true, // 开启滚轮平移
          moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
        }]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.tabs{
  border-bottom: 1px solid #213861;
  margin-bottom: 10px;
}
/deep/ .el-tabs__item{
  position: relative;
}
/deep/ .el-tabs__item::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 12px;
  background: #213861;
  left: -30px;
  top:50%;
  margin-top: -6px;
}
</style>