<template>
  <el-row class="page" style="margin: 0" :gutter="16">
    <el-col :span="6">
      <LeftTop></LeftTop>
      <LeftBottom class="mt16 mb16"></LeftBottom>
    </el-col>
    <el-col :span="12">
      <MapData :mapType="4" :height="663" ref="map" :mapindex="0"/>
      <div class="bottomContainer mt16">
        <div class="bottomItem" >
          <BottomLeft></BottomLeft>
        </div>
        <div class="bottomItem">
          <BottomRight></BottomRight>
        </div>
      </div>
    </el-col>
    <el-col :span="6">
      <RightTop></RightTop>
      <RightBottom></RightBottom>
    </el-col>
  </el-row>
</template>
<script>
import BottomLeft from './BottomLeft.vue'
import BottomRight from './BottomRight.vue'
import LeftBottom from './LeftBottom.vue'
import LeftTop from './LeftTop.vue'
import RightBottom from './RightBottom.vue'
import RightTop from './RightTop.vue'
import MapData from '@/views/homePage/overview/mapData.vue';

export default {
  components: { MapData, BottomLeft, BottomRight, LeftBottom, LeftTop, RightBottom, RightTop }
};
</script>
<style lang="scss">
@import '@/theme/bigScreen.scss';

.page {
  color: $main-text;
}
.bottomContainer {
  display: flex;
  gap: 16px;
  .bottomItem {
    height: 276px;
    flex: 1;
  }
}
</style>
